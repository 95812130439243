<template>
  <header class="header">
    <HeaderTop />

    <HeaderMiddle />

    <HeaderBottom />
  </header>
</template>

<script>
import HeaderTop from "./HeaderTop";
import HeaderMiddle from "./HeaderMiddle";
import HeaderBottom from "./HeaderBottom";

export default {
  components: {
    HeaderTop,
    HeaderMiddle,
    HeaderBottom,
  },
};
</script>
<style lang="scss" scoped>
@media print {
  .header{
    visibility: hidden;
  }
}
</style>