<template>
  <footer class="ps-footer" style="margin-top:20px">
    <div class="container-fluid-sy">
      <div class="ps-footer--contact">
        <div class="row">
          <div class="col-12 col-lg-3">
            <p class="contact__title">Contact Us</p>
            <p>
              <b><i class="icon-telephone"> </i> Hotline: </b
              ><span>Sri Lankan Time (9am - 6pm)</span>
            </p>
            <p class="telephone text-black">
              +1 (973) 341 0582<br /> +94 (768) 514 527
            </p>
            <p>
              <b>Email us: </b
              ><a class="mail" href="mailto:suyeemarket@gmail.com"
                >suyeemarket@gmail.com</a
              >
            </p>
          </div>
          <div class="col-12 col-lg-5">
            <div class="row">
              <div class="col-12 col-lg-6">
                <p class="contact__title">
                  About Us<span class="footer-toggle"
                    ><i
                      v-on:click="sub_menu_1 = !sub_menu_1"
                      class="icon-chevron-down pointer"
                    ></i
                  ></span>
                </p>
                <ul class="footer-list-solo hide" v-if="sub_menu_1">
                  <li class="">
                    <router-link :to="{ name: 'about' }">About Us</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'contact' }">Contact</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'help' }"
                      >Help and Support</router-link
                    >
                  </li>
                </ul>
                <hr />
              </div>
              <div class="col-12 col-lg-6">
                <p class="contact__title">
                  Info<span class="footer-toggle"
                    ><i
                      v-on:click="sub_menu_2 = !sub_menu_2"
                      class="icon-chevron-down pointer"
                    ></i
                  ></span>
                </p>
                <ul class="footer-list-solo hide" v-if="sub_menu_2">
                  <router-link :to="{ name: 'terms' }"
                    >Terms & Condition</router-link
                  >
                  <li>
                    <router-link :to="{ name: 'privacy' }"
                      >Privacy & Cookies policy</router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ name: 'returns' }"
                      >Returns & Cancellations policy</router-link
                    >
                  </li>
                </ul>
                <hr />
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4">
            <p class="contact__title">Newsletter Subscription</p>
            <p>
              Join our email subscription now to get updates on
              <b>promotions </b>and <b>coupons.</b>
            </p>
            <form @submit.prevent="subscribeMail()">
              <div class="form-group--block">
              <div class="input-group">
                <div class="input-group-prepend">
                  <i class="fa fa-envelope"></i>
                </div>
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $errors.has('subscriber_email') }"
                  type="text"
                  placeholder="Enter your email..."
                  v-model="formEmail.subscriber_email"
                />
                <div class="input-group-append">
                  <button class="btn" type="submit" :disabled="isLoading">{{ isLoading ? 'subscribing...' : 'Subscribe' }}</button>
                </div>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row ps-footer__copyright">
        <div class="col-12 col-lg-8 ps-footer__text">
          &copy; {{ $currentDate('YYYY') }} Suyee & Family Marketting. All Rights Reversed.
        </div>
        <div class="col-12 col-lg-3 ps-footer__social">
          <a
            class="icon_social facebook"
            href="https://www.facebook.com/SuyeeMarket/"
            target="”_blank”"
            ><i class="fa fa-facebook-f"></i></a
          ><a
            class="icon_social instagram"
            href="https://www.instagram.com/suyeemarket"
            target="”_blank”"
            ><i class="fa fa-instagram"></i
          ></a>
          <a
            class="icon_social youtube"
            href="https://www.youtube.com/"
            target="”_blank”"
          >
            <i class="fa fa-youtube"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      sub_menu_1: true,
      sub_menu_2: true,
      isLoading:false,
      formEmail: {
        subscriber_email: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["subscribeEmail"]),

    subscribeMail() {
      this.isLoading = true
      this.subscribeEmail(this.formEmail).then(() => {
        this.isLoading = false
        // this.$router.push({ name: "login" });
      }).catch(()=>{
         this.isLoading = false
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.footer-list-solo {
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
  transition: all 0s ease;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
@media print {
  .ps-footer,
  .print-hide {
    visibility: hidden;
  }
  .hide {
    visibility: hidden;
  }
}
</style>