<template>
  <div class="ps-footer-mobile">
    <div class="menu__content">
      <ul class="menu--footer">
        <li class="nav-item">
          <router-link
            :to="{name: 'home'}"
            class="nav-link"
          >
            <i class="icon-home3"></i><span>Home</span>
          </router-link>
        </li>

        <li class="nav-item">
          <a
            class="nav-link footer-category"
            href="javascript:void(0);"
            @click="toggleCateMenu"
          ><i class="icon-list"></i><span>Category</span></a>
        </li>

        <li class="nav-item">
          <router-link
            :to="{name: 'cart'}"
            class="nav-link footer-cart"
          >
            <i class="icon-cart"></i><span class="badge bg-warning">
              {{ cart ? cart.items_count : 0 }}</span><span>Cart</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{name:'wishlist'}"
            class="nav-link"
          >
            <i class="icon-heart"></i><span class="badge bg-warning">
              {{ items_count || 0 }}</span><span>Wishlist</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            :to="{name: 'dashboard'}"
            class="nav-link"
          >
            <i class="icon-user"></i><span>Account</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    openCategoryMenu: {
      type: Boolean,
    },
  },

computed: {
    ...mapGetters("cart", ["cart"]),
    ...mapGetters("wishlist", ["items_count"]),
  },

  methods: {
     ...mapActions('home',['assignState']),
    toggleCateMenu() {
       this.assignState({key:'mobileCategoryMenuState',value:true})
    },

    closeCateMenu() {
      this.$emit("update:openCategoryMenu", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .ps-footer-mobile{
    visibility: hidden;
  }
}
</style>