<template>
  <div>
    <div class="ps-header--center header--mobile">
      <div class="container-fluid-sy p-0">
        <div class="header-inner">
          <div class="header-inner__left">
            <button class="navbar-toggler" @click="openNavMenu">
              <i class="icon-menu"></i>
            </button>
          </div>
          <div class="header-inner__center">
            <router-link :to="{ name: 'home' }" class="logo">
            <img src="../../assets/img/m_logo.png" class="logo" alt="logo"
            /></router-link>
          </div>
          <div class="header-inner__right">
            <button class="button-icon icon-sm search-mobile" @click="openSearchSlidebar">
              <i class="icon-magnifier"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <section class="ps-header--center header-desktop">
      <div class="container-fluid-sy">
        <div class="header-inner">
          <div class="header-inner__left">
            <router-link :to="{ name: 'home' }" class="logo">
              <img src="../../assets/img/logo.png" class="logo" alt="logo" />
            </router-link>
          </div>

          <div class="header-inner__center">
            <!-- trending-search -->
            <header-search/>
          </div>

          <div class="header-inner__right">
            <router-link class="button-icon icon-md" :to="{ name: 'wishlist' }">
              <i class="icon-heart"></i
              ><span class="badge bg-warning">{{ items_count || 0 }}</span>
            </router-link>

            <div
              class="button-icon btn-cart-header"
              @click="miniCartOpen = !miniCartOpen"
              v-click-outside="closeMiniCart"
            >
              <i class="icon-cart icon-shop5"></i>
              <span class="badge bg-warning">
                {{ cart ? items.length : 0 }}
              </span>
              <MiniCart :class="{ open: miniCartOpen }" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeaderSearch from './HeaderSearch.vue';
import MiniCart from "./HeaderMiniCart";

export default {
  data() {
    return {
      miniCartOpen: false,
    };
  },
  components: {
    MiniCart,HeaderSearch
  },

  computed: {
    ...mapGetters("cart", ["cart",'items']),
    ...mapGetters("wishlist", ["items_count"]),
  },

  methods: {
    ...mapActions('home',['assignState']),
    closeMiniCart() {
      this.miniCartOpen = false;
    },

    openNavMenu() {
      this.assignState({key:'mobileMenuState',value:true})
    },
    openSearchSlidebar(){
      var SearchSlidebar = document.getElementById("SearchSlidebar");
      SearchSlidebar.classList.add("open");      
    }
  },
};
</script>
<style lang="scss" scoped>
.logo {
  height: 64px;
}
</style>