<template>
  <div class="ps-top-bar">
    <div class="container-fluid-sy">
      <div class="top-bar">
        <div class="top-bar__left">
          <ul class="nav-top">
            <li class="nav-top-item contact">
              <a
                class="nav-top-link"
                @click="whatsapp()"
                href="tel:+19733410582"
              >
                <i class="icon-telephone"></i>
                <span class="font-bold">Hotline:</span>
                <span class="font-bold"> +1 (973) 341 0582 / +94 (768) 514 527</span></a>
            </li>
          </ul>
        </div>
        <div class="top-bar__right">
          <ul class="nav-top">
            <li class="nav-top-item">
              <router-link :to="{ name: 'payment-services' }">
                <span class="font-bold pay-option">Payment options</span>
              </router-link>
            </li>
            <li class="nav-top-item account" v-if="isAuthenticated">
              <a class="nav-top-link" href="javascript:void(0);">
                <i class="icon-user"></i>Hi!
                <span class="font-bold">{{ user.name }}</span></a
              >
              <div class="account--dropdown">
                <div class="account-anchor">
                  <div class="triangle"></div>
                </div>
                <div class="account__content">
                  <ul class="account-list">
                    <li>
                      <router-link :to="{ name: 'dashboard' }"
                        >Dashboard</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'account-setting' }"
                        >Account Setting</router-link
                      >
                    </li>

                    <li>
                      <router-link :to="{ name: 'user-orders-history' }"
                        >Orders History</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'wishlist' }"
                        >Wishlist</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="{ name: 'address' }"
                        >Billing Address</router-link
                      >
                    </li>
                  </ul>
                  <hr />
                  <a
                    class="account-logout text-danger"
                    href="javascript:void(0)"
                    @click="logout()"
                    ><i class="icon-exit-left text-danger"></i>Logout</a
                  >
                </div>
              </div>
            </li>
            <li class="nav-top-item" v-if="!isAuthenticated">
              <router-link :to="{ name: 'login' }" class="font-bold"
                >Login</router-link
              >
            </li>
            <li class="nav-top-item" v-if="!isAuthenticated">
              <router-link :to="{ name: 'register' }">Register</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["user", "isAuthenticated"]),
  },

  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "home" }));
    },
    whatsapp:function () {
          window.open("https://wa.me/+19733410582?text=Hi there!", "_blank");  
    },
  },
};
</script>
<style lang="scss" scoped>
.pay-option {
  color: #535353;
}
</style>