<template>
  <div
    class="ps-category--mobile"
    :class="{ open: mobileCategoryMenuState }"
    id="categoryMobile"
  >
    <div class="category__header">
      <div class="category__title">All Categories</div>
      <span class="category__close" @click="closeMenu"
        ><i class="icon-cross"></i
      ></span>
    </div>
    <div class="category__content">
      <ul class="menu--mobile">
        <template
          v-for="(category, index) in mainCategories"
          :key="index"
        >
          <CategoryMobileRow :category="category" :index="index" />
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategoryMobileRow from "./CategoryMobileRow.vue";
export default {
  data() {
    return {
      isActive: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CategoryMobileRow,
  },
  mounted() {
    if (this.categories_subcategories && !this.categories_subcategories.length) {
      this.fetchCategoriesTree();
    }

    document
      .querySelector(".category__close")
      .addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(".ps-category--mobile").classList.toggle("open");
      });
  },

  computed: {
    ...mapGetters("categories", ["categories_subcategories",'mainCategories']),
    ...mapGetters("home", ["mobileCategoryMenuState"]),
  },

  watch: {
    $router: {
      handler() {
        this.assignState({ key: "mobileCategoryMenuState", value: false });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("categories", ["fetchCategoriesTree"]),
    ...mapActions("home", ["assignState"]),
    closeMenu() {
      this.assignState({ key: "mobileCategoryMenuState", value: false });
    },
  },
};
</script>
<style scoped>
.category__close {
  padding: 10px 0 0 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
}
</style>