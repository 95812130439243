<template>
  <!-- header -->
  <TheHeader />

  <router-view></router-view>

  <TheFooter  v-show="!$isMobile()"/>

  <FooterMobile :openCategoryMenu="openCategoryMenu" @update:openCategoryMenu="openCategoryMenu = $event" />

  <button class="btn scroll-top"><i class="icon-chevron-up"></i></button>

  <CategoryMobile :open="openCategoryMenu" />

  <NavigationMobile />

  <MobileSearchSlidebar/>

</template>

<script>
import TheHeader from "@/views/Components/TheHeader";
import TheFooter from "@/views/Components/TheFooter";
import FooterMobile from "./Components/FooterMobile";
import CategoryMobile from "./Components/CategoryMobile";
import NavigationMobile from "./Components/NavigationMobile";
import MobileSearchSlidebar from './Components/MobileSearchSlidebar';
import { mapActions,mapGetters } from 'vuex';

export default {
  components: {
    TheHeader,
    TheFooter,
    FooterMobile,
    CategoryMobile,
    NavigationMobile,
    MobileSearchSlidebar,
  },

  data() {
    return {
      openCategoryMenu: false,
    }
  },
  computed:{
    ...mapGetters("country", ["ipInfo"]),
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("wishlist", { wishListIsLoaded:'loaded' }),
  },
 
  watch:{
    '$route':{
      handler(){
        if(this.isAuthenticated && !this.wishListIsLoaded) {
          this.fetchList();
        }
        this.fetchCart();
      },
      immediate: true,
    },

    ipInfo: {
      handler() {
        if (this.ipInfo && !this.ipInfo.visible_status) {
          this.$router.push({ name: "not-available" });
        }
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    ...mapActions('cart',['fetchCart']),
    ...mapActions('wishlist',['fetchList'])
  },
};
</script>