<template>
  <nav class="navigation ps-header--center header-desktop">
    <div class="container-fluid-sy header-inner__left">
      <ul class="menu">
        <li class="menu-item-has-children has-mega-menu">
          <button class="category-toggler">
            <i class="icon-menu" style="color: #000000"></i>
          </button>
          <div class="mega-menu mega-menu-category">
            <ul class="menu--mobile menu--horizontal">
              <template
                v-for="(category, index) in mainCategories"
                :key="index"
              >
                <li
                  :class="{ 'has-mega-menu ': category.childs }"
                  class="category-item"
                >
                  <a @click="filterCategory(category.id)">{{ category.name }}</a
                  >
                  <span class="sub-toggle" v-if="category.childs.length > 0"
                    ><i class="icon-chevron-down"></i
                  ></span>
                  <div class="mega-menu mega-menu-sub-category" v-if="category.childs.length > 0" >
                    <div class="mega-anchor"></div>
                    <div class="mega-menu__column">
                      <ul class="sub-menu--mega">
                        <li v-for="(sub_category, index) in category.childs" :key="index">
                        
                            <a href="#" @click.prevent="$router.push({path: '/products?limit=12&',
                              query: { category_id: sub_category.id }})">{{ sub_category.name }}</a>
                        </li>

                        <li class="see-all">
                          <a href="#" @click.prevent="$router.push({path: '/products?limit=12&'})">See all products <i class="icon-chevron-right"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </li>
      </ul>

      <ul class="menu">
        <li class="menu-item-has-children has-mega-menu">
          <router-link :to="{ name: 'home' }">Home</router-link>
        </li>

        <li class="menu-item-has-children has-mega-menu">
          <a aria-current="page" :href="`/products?category_id=${135}`" 
            @click.prevent="$router.push({ name: 'products', query:{ limit:12 ,category_id:135} })" 
            :class="{'exact-active active': isActive(135) }"
          >Flash Deals</a>

        </li>

        <li class="menu-item-has-children has-mega-menu" v-for="(category, index) in categories_subcategories" :key="index">
          <a aria-current="page" :href="`/products?category_id=${category.id}`" 
          @click.prevent="$router.push({ name: 'products', query:{ limit:12 ,category_id:category.id} })" 
            :class="{'exact-active active': isActive(category.id) }"
          >{{category.name}}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  mounted() {
    if (this.categories_subcategories && !this.categories_subcategories.length) {
      this.fetchCategoriesTree();
    }
    if (this.mainCategories && !this.mainCategories.length) {
      this.fetchAllMainCategoriesTree();
    }
  },

  computed: {
    ...mapGetters("categories", ["categories_subcategories",'mainCategories']),
  },

  methods: {
    filterCategory(id) {
      var $data = { category_id: id };
      this.$router.push({ name: "products", query: $data });
    },
    ...mapActions("categories", ["fetchCategoriesTree",'fetchAllMainCategoriesTree']),
    isActive (id) {
      const current = parseFloat(this.$route.query.category_id)
      return current === id
    }
  },
};
</script>
<style lang="scss" scoped>
.ps-block__view {
  cursor: pointer;
}
.mega-menu-sub-category{
  min-width: 235px !important;
}
</style>