<template>
  <div v-click-outside="outsideClicked">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="header-search-select">
          <span class="current">All<i class=""></i></span>
          <ul class="list">
            <li class="category-option active" data-value="option">
              <a href="javascript:void(0);">All</a>
            </li>
            <li class="category-option">
              <a>Fresh</a
              ><span class="sub-toggle"><i class="icon-chevron-down"></i></span>
              <ul>
                <li><a href="#">Meat & Poultry</a></li>
                <li><a href="#">Fruit</a></li>
                <li><a href="#">Vegetables</a></li>
                <li><a href="#">Milks, Butter & Eggs</a></li>
                <li><a href="#">Fish</a></li>
                <li><a href="#">Frozen</a></li>
                <li><a href="#">Cheese</a></li>
                <li><a href="#">Pasta & Sauce</a></li>
              </ul>
            </li>
            <li class="category-option">
              <a>Food Cupboard</a
              ><span class="sub-toggle"><i class="icon-chevron-down"></i></span>
              <ul>
                <li><a href="#">Crisps, Snacks & Nuts</a></li>
                <li><a href="#">Breakfast Cereals</a></li>
                <li><a href="#">Tins & Cans</a></li>
                <li><a href="#">Chocolate & Sweets</a></li>
              </ul>
            </li>
            <li class="category-option" data-value="Bakery">
              <a href="javascript:void(0);">Bakery</a>
            </li>
            <li class="category-option" data-value="Drinks, Tea &amp; Coffee">
              <a href="javascript:void(0);">Drinks, Tea &amp; Coffee</a>
            </li>
            <li class="category-option" data-value="Frozen Foods">
              <a href="javascript:void(0);">Frozen Foods</a>
            </li>
            <li class="category-option">
              <a>Ready Meals</a
              ><span class="sub-toggle"><i class="icon-chevron-down"></i></span>
              <ul>
                <li><a href="#">Traditional British</a></li>
                <li><a href="#">Indian</a></li>
                <li><a href="#">Italian</a></li>
                <li><a href="#">Chinese</a></li>
              </ul>
            </li>
            <li class="category-option" data-value="Beer, Wine &amp; Spirits">
              <a href="javascript:void(0);">Beer, Wine &amp; Spirits</a>
            </li>
            <li class="category-option" data-value="Baby &amp; Child">
              <a href="javascript:void(0);">Baby &amp; Child</a>
            </li>
            <li class="category-option" data-value="Kitchen &amp; Dining">
              <a href="javascript:void(0);">Kitchen &amp; Dining</a>
            </li>
          </ul>
        </div>
        <i class="icon-magnifier search"></i>
      </div>
      <input
        class="form-control input-search"
        placeholder="I'm searchching for..."
        v-model="query"
        @keyup.stop="handleSearch"
        @focus="showResult = true"
      />
      <div class="input-group-append">
        <button class="btn">Search</button>
      </div>
    </div>

    <div class="result-search" :class="{ open: showResult }">
      <ul class="list-result">
        <template v-if="!searching">
          <template v-if="results.length">
            <li class="cart-item" v-for="(item, index) in results" :key="index">
              <div class="ps-product--mini-cart">
                <a href="#" @click.prevent="viewProduct(item)">
                  <img class="ps-product__thumbnail" :src="item.base_image.small_image_url" alt="alt">
                </a>
                <div class="ps-product__content">
                  <a
                    class="ps-product__name"
                    href="#"
                    @click.prevent="viewProduct(item)"
                    >{{ item.name }}</a
                  >

                  <p
                    class="ps-product__meta"
                    v-if="item.formated_special_price"
                  >
                    <span class="ps-product__price-sale">{{
                      item.formated_special_price
                    }}</span>
                    <span class="ps-product__is-sale">{{
                      item.formated_regular_price
                    }}</span>
                  </p>

                  <p class="ps-product__meta" v-else>
                    <span class="ps-product__price">{{ item.formated_price }}</span>
                  </p>
                </div>
              </div>
            </li>
          </template>
          <template v-else>
            <li class="cart-item">
              <div class="ps-product--mini-cart">
                <div class="ps-product__content">
                  <a class="ps-product__name">No result...</a>
                </div>
              </div>
            </li>
          </template>
        </template>
        <template v-else>
          <li class="cart-item" v-for="(count, index) in 5" :key="index">
            <div class="ps-product--mini-cart">
               <a href="#">
                 <Skeletor height="50" width="50" />
               </a>
              <div class="ps-product__content">
                <a class="ps-product__name" href="#">
                  <Skeletor height="15" width="30%" />
                </a>
                <p class="ps-product__meta">
                  <span class="ps-product__price"
                    ><Skeletor height="10" width="20%"
                  /></span>
                </p>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

import axios from "axios";

import { API_URL } from "@/common/config";
import { mapActions } from "vuex";

var cancel;
var CancelToken = axios.CancelToken;

export default {
  components: { Skeletor },
  data() {
    return {
      query: "",
      showResult: false,
      searching: false,
      results: [],
    };
  },
  methods: {
    ...mapActions("product", ["fetchProduct"]),
    setSearchResult(e) {
      if (e.length) {
        this.showResult = true;
      } else {
        this.showResult = false;
      }
    },
    outsideClicked(){
      this.showResult = false
    },
    viewProduct(item) {
      this.showResult = false;
      this.$router.push({
        name: "product-view",
        params: { productId: item.id },
        force: true,
      });
      this.fetchProduct(item.id);
    },

    async handleSearch() {
      // this.setSearchResult(this.query);
      this.preApiCall();
    },

    preApiCall() {
      if (cancel != undefined) {
        cancel();
      }
      this.apiCall(this.query);
    },

    apiCall(query) {
      if (query !== "") {
        this.showResult = true;
        this.searching = true;
        axios({
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          method: "get",
          url: `${API_URL}/products-search`,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          params: { search: query, token: 1 },
        })
          .then((res) => {
            this.results = res.data.data;
            this.searching = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>