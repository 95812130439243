<template>
  <div class="mobile-search--slidebar" id="SearchSlidebar">
    <div class="mobile-search--content">
      <div class="mobile-search__header">
        <div class="mobile-search-box">
          <div class="input-group">
            <input
              class="form-control"
              placeholder="I'm shopping for..."
              id="inputSearchMobile"
              v-model="query"
              @keyup.stop="handleSearch"
            />
            <div class="input-group-append">
              <button class="btn"><i class="icon-magnifier"></i></button>
            </div>
          </div>
          <button class="cancel-search" @click="closeSearchSlidebar">
            <i class="icon-cross"></i>
          </button>
        </div>
      </div>

      <div class="mobile-search__history" :class="{ open: showResult }">
        <div class="history-content">
          <ul class="list-result">
            <template v-if="!searching">
              <template v-if="results.length">
                <li
                  class="cart-item mb-2"
                  v-for="(item, index) in results"
                  :key="index"
                >
                  <div class="ps-product--mini-cart">
                    <a href="#">
                      <img
                        class="ps-product__thumbnail"
                        :src="item.base_image.small_image_url"
                        alt="alt"
                      />
                    </a>
                    <div class="ps-product__content">
                      <a
                        class="ps-product__name"
                        @click.prevent="viewProduct(item)"
                        >{{ item.name }}</a
                      >
                      <p
                        class="ps-product__meta"
                        v-if="item.formated_special_price"
                      >
                        <span class="ps-product__price-sale">
                          {{ item.formated_special_price }}
                        </span>
                        <span class="ps-product__is-sale">
                          {{ item.formated_regular_price }}
                        </span>
                      </p>

                      <p class="ps-product__meta" v-else>
                        <span class="ps-product__price">{{ item.formated_price }}</span>
                      </p>
                    </div>
                  </div>
                </li>
              </template>
              <template v-else>
                <li class="cart-item mb-2">
                  <div class="ps-product--mini-cart">
                    <div class="ps-product__content">
                      <a class="ps-product__name">No result...</a>
                    </div>
                  </div>
                </li>
              </template>
            </template>            
            <template v-else>
              <li class="cart-item" v-for="(count, index) in 5" :key="index">
                <div class="ps-product--mini-cart">
                  <div class="ps-product__content">
                    <a class="ps-product__name" href="#">
                      <Skeletor height="15" width="30%" />
                    </a>
                    <p class="ps-product__meta">
                      <span class="ps-product__price"
                        ><Skeletor height="10" width="20%"
                      /></span>
                    </p>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Skeletor } from "vue-skeletor";
import axios from "axios";
import { API_URL } from "@/common/config";
import { mapActions } from "vuex";
var cancel;
var CancelToken = axios.CancelToken;

export default {
  components: { Skeletor },
  data() {
    return {
      query: "",
      showResult: false,
      searching: false,
      results: [],
    };
  },
  methods: {
    ...mapActions("product", ["fetchProduct"]),
    
    closeSearchSlidebar() {
      var SearchSlidebar = document.getElementById("SearchSlidebar");
      SearchSlidebar.classList.remove("open");
    },
    viewProduct(item) {
      this.showResult = false;
      this.$router.push({
        name: "product-view",
        params: { productId: item.id },
        force: true,
      });
      this.closeSearchSlidebar()
      this.fetchProduct(item.id);
    },

    setSearchResult(e) {
      if (e.length) {
        this.showResult = true;
      } else {
        this.showResult = false;
      }
    },

    async handleSearch() {
      this.setSearchResult(this.query);
      this.preApiCall();
    },

    preApiCall() {
      if (cancel != undefined) {
        cancel();
        console.log("cancelled");
      }
      this.apiCall(this.query);
    },

    apiCall(query) {
      if (query !== "") {
        this.searching = true;
        axios({
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          method: "get",
          url: `${API_URL}/products-search`,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          params: { search: query, token: 1 },
        })
          .then((res) => {
            this.results = res.data.data;
            this.searching = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.clear();
      }
    },
  },
};
</script>