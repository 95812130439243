<template>
  <nav class="navigation--mobile" :class="{'open':mobileMenuState}" id="navigationMobile">
    <div class="navigation__header">
      <div class="navigation-title">
        <button class="close-navbar-slide" @click="closeMenu">
          <i class="icon-arrow-left"></i>
        </button>
        <div v-if="isAuthenticated">
          <span> <i class="icon-user"></i>Hi, </span>
          <router-link :to="{ name: 'dashboard' }"><span class="account">{{ user.name }}</span></router-link>
        </div>               
        <div class="loginlinks" v-if="!isAuthenticated">
          <router-link :to="{ name: 'login' }" v-if="!isAuthenticated">Login <span>/</span> </router-link>              
          <router-link :to="{ name: 'register' }" v-if="!isAuthenticated"> Register</router-link>  
        </div>
      </div>
    </div>             

    <div class="navigation__content">
      
      <ul class="menu--mobile">
        <li class="daily-deals menu-item-has-children" @click="closeMenu">
          <router-link class="captila" :to="{ name: 'home' }">Home</router-link>
        </li>
        <li class="menu-item-has-children">
        </li>
        <li class="menu-item-has-children">
          <a class="dis-color">My Account</a>
        </li>
      </ul>
      <ul class="menu--mobile">
        <li class="menu-item-has-children" @click="closeMenu">
          <router-link class="captila" :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="menu-item-has-children" @click="closeMenu">
          <router-link class="captila" :to="{ name: 'account-setting' }">Account Setting</router-link>
        </li>
        <li class="menu-item-has-children" @click="closeMenu">
          <router-link class="captila" :to="{ name: 'user-orders-history' }">Orders History</router-link>
        </li>
        <li class="menu-item-has-children" @click="closeMenu">
          <router-link class="captila" :to="{ name: 'address' }">Billing Address</router-link>
        </li>
        <li class="menu-item-has-children" @click="closeMenu">
          <router-link class="captila" :to="{ name: 'wishlist' }">Wishlist</router-link>
        </li>
        <li class="menu-item-has-children" @click="closeMenu">
          <a class="dis-color">Quick links</a>
        </li>

        <li @click="closeMenu">
          <router-link class="captila" :to="{ name: 'payment-services' }">Payment Option</router-link>
        </li>
        <li @click="closeMenu">
          <router-link class="captila" :to="{ name: 'about' }">About Us</router-link>
        </li>
        <li @click="closeMenu">
          <router-link class="captila" :to="{ name: 'contact' }">Contact</router-link>
        </li>
        <li @click="closeMenu">
          <router-link class="captila" :to="{ name: 'terms' }">Terms & Condition</router-link>
        </li>
        <li @click="closeMenu">
          <router-link class="captila" :to="{ name: 'help' }">Help and Support</router-link>
        </li> 
        <li class="menu-item-has-children" @click="closeMenu">
          <a class="dis-color">Social links</a>
        </li>
        <li>
          <a href="https://www.facebook.com/SuyeeMarket/" class="captila"><i class="fa fa-facebook-f"></i> Facebook</a>
        </li> 
        <li>
          <a href="https://www.youtube.com/" class="captila"><i class="fa fa-youtube"></i> Youtube</a>
        </li>
        <li>
          <a href="https://www.instagram.com/suyeemarket" class="captila"><i class="fa fa-instagram"></i> Instagram</a>
        </li>
        <div class="logout" v-if="isAuthenticated">
          <a class="account-logout text-danger" href="javascript:void(0)" @click="logout()">
            <i class="icon-exit-left text-danger"></i> Logout</a>
        </div>
      </ul>
    </div>
    

    <div class="navigation__footer">
      <ul class="menu--icon">
        <li class="footer-item">
          <a class="footer-link"
            ><i class="icon-question-circle"></i><span>E-mail : <a class="mail" href="mailto:suyeemarket@gmail.com">suyeemarket@gmail.com</a></span></a
          >
        </li>
        <li class="footer-item">
          <a class="footer-link" href="#"
            ><i class="icon-telephone"></i
            ><span
              >HOTLINE:
              <span class="text-success"><a href="tel:+94768514527">+94 (768) 514 527</a></span></span
            ></a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data(){
    return{
      sub_menu: true,
      sub_menu2: true,
      menuState:false
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isAuthenticated"]),
    ...mapGetters('home',['mobileMenuState'])
  },
  watch:{
    '$router':{
      handler(){
        this.assignState({key:'mobileMenuState',value:false});
      },
      deep:true,
      immediate:true
    }
  },
  methods: {
    ...mapActions('home',['assignState']),
    closeMenu() {
       this.assignState({key:'mobileMenuState',value:false})
    },
    whatsapp:function () {
          window.open("https://wa.me/+19733410582?text=Hi there!", "_blank");  
    },

    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "home" }));
    },
    
  },
};
</script>
<style lang="scss" scoped>
.sub-menu-toggle > li > .link {
  display: inline-block;
  padding: 10px 20px;
  width: 100%;
  font-size: 15px;
  color: #737373;
  text-transform: capitalize;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.loginlinks{
  color: #ffff;
}
.dis-color{
  color: #a5a4a4;
  font-size: 15px;
  padding: 6px 0px 6px; 
  text-transform: capitalize;
}
.captila{
  text-transform: capitalize;
  padding: 8px 0px 8px; 
  font-size: 14px;
  color: #2e2e2e;
}
.fa{  
  color: white;
  padding: 2px 4px 2px 4px;
  font-size: 10px;
}
.fa-facebook-f{
  background-color: #1877F2;
}
.fa-instagram{
  background-color: #E4405F;
}
.fa-youtube{
  background-color: #CD201F;
}
.logout{
  margin-top: 30px;
}
.logout > a{
  font-weight: bold;
  color: red;
}
.mail:hover{
  color: #1877F2;
}
@media print {
  .navigation--mobile{
    visibility: hidden;
  }
}
</style>