<template>
  <li
    :class="{ 'menu-item-has-children': category.childs }"
    class="category-item"
    :id="'list' + index"
  >
    <router-link
      :to="{
        path: '/products?limit=12&',
        query: { category_id: category.id },
      }"
      class="link"
      >{{ category.name }}</router-link
    >

    <span
      v-if="category.childs.length > 0"
      class="sub-toggle"
      @click="submenu(index)"
      ><i class="icon-chevron-down"></i
    ></span>
    <ul v-if="category.childs.length > 0" class="sub-menu">
      <li v-for="(sub_category, index) in category.childs" :key="index">
        <router-link
          :to="{
            path: '/products?limit=12&',
            query: { category_id: sub_category.id },
          }"
          >{{ sub_category.name }}</router-link
        >
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    submenu(index) {
      var subToggle = document.getElementById("list" + index);
      subToggle.classList.toggle("active");
    },
  },
};
</script>