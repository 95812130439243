<template>
  <div class="mini-cart">
    <div class="mini-cart--content">
      <div class="mini-cart--overlay"></div>
      <div class="mini-cart--slidebar cart--box" :style="isEmpty ? 'display: flex;min-height: 50px;height: 50px;place-content: center;' : ''">
        <div class="mini-cart__header">
          <div class="cart-header-title">
            <h5>Shopping Cart({{items_count}})</h5>
            <a
              class="close-cart"
              href="javascript:void(0);"
            ><i class="icon-arrow-right"></i></a>
          </div>
        </div>
        <template v-if="!isEmpty">
          <div class="mini-cart__products">
            <div class="out-box-cart">
              <div class="triangle-box">
                <div class="triangle"></div>
              </div>
            </div>
            <ul class="list-cart">
              <li class="cart-item" v-for="(item, index) in items" :key="index">
                <div class="ps-product--mini-cart">
                  <router-link 
                    :to="{ name: 'product-view', params: {productId: item.product.id}}">
                    <img class="ps-product__thumbnail" :src="item.product.base_image.small_image_url" :alt="item.name"/>
                  </router-link>

                  <div class="ps-product__content">
                    <router-link class="ps-product__name"
                      :to="{ name: 'product-view', params: {productId: item.product.id}}">
                      {{ item.name }}
                    </router-link>
                    <p class="ps-product__meta">
                      <span class="ps-product__price">{{item.formated_price}}</span>
                      <span class="ps-product__quantity">(x{{item.quantity}})</span>
                    </p>
                  </div>
                  
                  <div class="ps-product__remove">
                    <a href="" @click.prevent="removeItem(item.id)">
                      <i class="icon-trash2"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="mini-cart__footer row">
            <div class="col-6 title">TOTAL</div>
            <div class="col-6 text-right total">{{cart.formated_base_sub_total}}</div>
            <div class="col-12 d-flex">
              <router-link
                class="view-cart"
                :to="{ name: 'cart' }"
              >
                View cart</router-link>
              <router-link
                class="checkout"
                :to="{ name: 'checkout' }"
              >Checkout</router-link>
            </div>
          </div>
        </template>
         <div v-else>
          <p style="text-align:center;margin-top:10px">Cart Empty</p>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed:{
    ...mapGetters('cart',['items','cart','isEmpty', 'items_count'])
  },
  methods: {
    ...mapActions('cart',['removeItem'])
  },
}
</script>